<script>
  import { fade } from 'svelte/transition';
  import Haiku from '../../public/assets/images/bghaiku.svelte'
  import { KitsuneLarge, AscensionBlockPlain, TicketBox, RoninLarge, KitsuneClaimedBlock, KitsuneClaimedBackground } from '../../public/assets/urls'
  import HazeLayer from '../components/shared/HazeLayer.svelte'
  import SuccessLayer from '../components/shared/SuccessLayer.svelte'
  import { navigateTo } from 'svelte-router-spa';

  import TokenSearch from '../components/TokenSearch.svelte';

  import {metadata, requestKitsuneMetadata} from "../stores/kitsuneMetadata";

  import {data, claimKitsunes,

    onStartClaimingKitsune,
    onCancelClaimingKitsune,
    onFailClaimingKitsune,
    onFinishClaimingKitsune,

  } from "../stores/manager";

  import {get} from "svelte/store";

  export let currentRoute;
  let claimId = currentRoute.namedParams.claimId;

  // Opening text for Kitsune page.  No Ronin has been chosen from NFT's page.
  let start;
  if(!claimId){
    start = true;
  }else{
    start = false;
  }

  let initialState = true;

  // User has selected a Ronin to claim Kitsune for, but there is more than one available to claim.
  let moreKitsune = false;
  $:{
    if(initialState){
      moreKitsune = (claimId) && $data.unclaimedKitsunes.value.length > 1;
    }

  }

  // Total Kitsune available to claim, used to display when moreKitsune is true, and confirming claiming of multiple.
  let kitsuneAvailable = 0;
  let kitsuneMutlipleCount = 0;
  $:{
    if(initialState){
      kitsuneAvailable = $data.unclaimedKitsunes.value.length;
    }
  }
  $:{
    kitsuneMutlipleCount = Math.min(20,kitsuneAvailable);
  }


  // User is on the page to confirm claim of 1 Kitsune.
  let confirmClaim = false;
  $:{
    if(initialState){
      confirmClaim = (claimId);
    }
  }

  // User is on the page to confirm claim multiple Kitsune.
  let confirmMultipleClaim = false;

  // User has clicked to confirm the claim of 1 or many Kitsune, and is waiting for processing.
  // confirmClaim or confirmClaimMultiple stays true when this is set true.
  let processingClaim = false;

  // Error processing the claim.
  let errorProcessing = false;

  // Final claimed screen should display, 1 Or more Kitsune has been claimed.
  let claimed = false;

  // Multiple Kitsune have been claimed for the final screen.
  // claimed must also be true when this is true.
  let claimedMultiple = false;

  // Used after claim is processed, displays total Kitsune claimed, if multiple were claimed.
  let kitsuneClaimed = 0;

  // User selects a Ronin from their NFT's, this is the ID sent to this page.
  let roninID;
  let internalRoninID;
  if(claimId){
    internalRoninID = claimId;
    roninID = claimId % 10000;
  }

  // ID of the Kitsune claimed, or if multiple claimed the first 1.
  let kitsuneID;

  // The url for the Kitsune image claimed, or if multiple
  let kitsuneImageUrl;
  let kitsuneImageUrlLoadingPlaceholder = null;

  $:{
    if(!kitsuneID || !$metadata[kitsuneID] || $metadata[kitsuneID].state !== "loaded"){
      kitsuneImageUrl = kitsuneImageUrlLoadingPlaceholder;
    }else{
      kitsuneImageUrl = $metadata[kitsuneID].image;
    }
  }

  let paramPassed = [];

  // if (!claimed){
  //   if(start){
  //     // initial prompt
  //   }else{
  //     if(moreKitsune){
  //       //you have more
  //     }else if( confirmMultipleClaim || confirmClaim ) {
  //       //Are you sure you want to claim
  //       if( confirmMultipleClaim ){
  //         {kitsuneAvailable} kitsune
  //       }else{
  //         kitsune for ronin #
  //       }
  //     }else if( errorProcessing ){
  //       //error
  //     }
  //
  //     if(moreKitsune){
  //       BUTTON: claim {kitsuneAvailable} at once | clickClaimMultiple
  //       BUTTON: claim for 1                      | clickClaimJustOne
  //       BUTTON: cancel                           | clickCancel
  //
  //     }else if (confirmMultipleClaim || confirmClaim
  //       if(!processingClaim){
  //         BUTTON: claim                          | clickConfirm
  //         BUTTON: cancel                         | clickCancel
  //       }else{
  //         // processing...
  //       }
  //
  //     }else if( errorProcessing ){
  //       //tx cancelled
  //     }
  //   }
  // }else{
  //  if(claimedMultiple){
  //    {kitsuneClaimed} claimed
  //  }else{
  //    kitsune #{kitsuneId} claimed
  //  }
  // }


  const forwardToProfile = async () => {
    navigateTo('/yournfts');
  }

  const clickClaimMultiple = async ()=>{
    initialState = false;

    moreKitsune = false;
    confirmMultipleClaim = true;
  }
  const clickClaimJustOne = async ()=>{
    initialState = false;

    moreKitsune = false;
    confirmClaim = true;
  }

  const clickCancel = async()=>{
    navigateTo("ronin/"+internalRoninID);
  }
  const clickConfirm = async()=>{
    initialState = false;

    processingClaim = true;

    if(confirmMultipleClaim){


      let unclaimed = get(data).unclaimedKitsunes.value;
      if(unclaimed.length <= 20){
        paramPassed = unclaimed;
      }else{
        let toClaim = [String(parseInt(internalRoninID))];
        for(let i = 0; toClaim.length < 20; i++){
          if(parseInt(internalRoninID) !== parseInt(unclaimed[i])){
            toClaim.push(String(unclaimed[i]));
          }
        }

        paramPassed = toClaim;
      }

      claimedMultiple = true;

    }else{

      //implicit confirmClaim
      paramPassed = [internalRoninID];

      claimedMultiple = false;
    }


    // console.log("pp")
    // console.log(paramPassed)
    // console.log("p!",paramPassed.length)



    claimKitsunes(paramPassed);
  }

  const clickRetry = async()=>{
    initialState = false;

    claimKitsunes(paramPassed);
  }

  onStartClaimingKitsune(()=>{
    processingClaim = true;

  });
  onCancelClaimingKitsune(()=>{
    processingClaim = false;
    errorProcessing = true;
  });
  onFailClaimingKitsune(()=>{
    processingClaim = false;
    errorProcessing = true;
  })
  onFinishClaimingKitsune((_kitsuneId)=>{
    if(!processingClaim) return;

    // console.log("claimed:");
    // console.log(paramPassed);
    // console.log(paramPassed.length)



    if(claimedMultiple){
      paramPassed.unshift(_kitsuneId);

      kitsuneClaimed = paramPassed.length;
    }

    kitsuneID = _kitsuneId;

    requestKitsuneMetadata(kitsuneID)

    processingClaim = false;
    claimed = true;

    // claimedMultiple
  });


  // const confirmClaimForSingleRonin = async () => {
  //   // Begin processing the claim for a single Kitsune, belonging to roninID.
  //   // processingClaim should be set true.
  // }
  //
  // const confirmClaimForMultipleRonin = async () => {
  //   // Begin processing the claim for multiple / remaining Kitsune, belonging to roninID.
  //   // processingClaim should be set true.
  // }
  //
  // const cancelAndReturnToRonin = async () => {
  //   // Return to the Ronin profile that the claim was selected for, roninID.
  // }
</script>

<div id="main_section" class="container mx-auto sm:max-w-screen-sm lg:max-w-screen-2xl text-xs lg:text-base px-8 sm:px-24 md:px-10 lg:px-0 mt-24 lg:mt-28 text-white">
<!--  <div>-->
<!--    ==== {claimId}-->
<!--  </div>-->

<!--  <div>{kitsuneID},{$metadata[kitsuneID]},-->
<!--    {#if $metadata[kitsuneID]}-->
<!--      {$metadata[kitsuneID].state},-->
<!--    {/if}-->
<!--    {kitsuneImageUrl}</div>-->

  <div class="relative w-full">
    {#if !claimed}
      <div class="text-center w-full px-8 text-xs lg:text-base">
        Every Oni Ronin NFT has the ability to <span class="gold-color">claim an authentic Kitsune NFT.</span>
      </div>
    {/if}

    {#if claimed}
      <div class="mt-0 lg:mt-10 absolute left:0 sm:right-8 2xl:mr-10 z-10">
        <Haiku/>
      </div>
    {/if}

    <!-- Background layers: Normal, Claimed -->
    {#if !claimed}
      <HazeLayer/>
    {:else}
      <img src="{KitsuneClaimedBackground}" class="fixed h-full w-full top-0 left-0"/>
    {/if}

    <div class="flex flex-col-reverse lg:flex-row lg:px-8 lg:mt-8 xl:-mt-12">

      {#if !claimed}
        <div class="lg:w-1/2 lg:ml-0 -mt-4 lg:-mt-2" in:fade={{delay: 100, duration: 800}} out:fade={{delay: 100, duration: 800}}>
          <img src={KitsuneLarge} alt="Kitsune" class="w-full scale-125 sm:scale-110 relative z-20 lg:mt-12"/>
        </div>
      {/if}

      <div class="{claimed ? 'mx-auto max-w-screen-sm xl:max-w-screen-md' : 'lg:w-1/2'} mt-6 lg:mt-0 z-20" in:fade={{delay: 100, duration: 800}} out:fade={{delay: 100, duration: 800}}>

        {#if !claimed}

          <!-- Start page -->
          {#if start}

            <div class="text-left lg:ml-16 lg:mr-12 h-full flex flex-col justify-center" in:fade>
              <h1 class="main-font text-4xl xl:text-7xl font-bold">KITSUNE</h1>
              <h1 class="main-font text-sm xl:text-xl font-bold mb-2">COMPANIONS</h1>

              <p class="mb-4 lg:mb-8 small-font">
                Though Oni Ronin serve no lord, they are not alone.  Every Oni Ronin has a Kitsune companion, a fox spirit warped in its form like the Oni Ronin themselves.
              </p>

              <p class="mb-4 lg:mb-8 small-font">
                Kitsune act as guides for the Ronin in the Oni-realm; they both watch and show the way.
              </p>

              <p class="mb-2 lg:mb-4 small-font">
                Each Oni Ronin has a separate Kitsune companion NFT <span class="gold-color">that can be claimed for free, exclusively by Oni Ronin holders</span>
              </p>

              <div class="float-right">
                <button class="gold-background px-4 lg:px-14 py-2 mt-4 lg:mt-8 secondary-font text-black rounded-full text-xs sm:text-base float-right sm:float-left" on:click={forwardToProfile}>CHOOSE A RONIN</button>
              </div>
            </div>

          <!-- Ticketbox, choosing and confirming -->
          {:else}

            <!-- <div class="mx-1 -mt-8 lg:mt-0 -mb-40 mx-6 md:mx-6 lg:ml-0 lg:mr-8 xl:ml-16 xl:mx-24 2xl:mr-32"> -->
            <div class="text-left lg:ml-16 lg:mr-8 xl:ml-32 xl:mr-16 h-full flex flex-col justify-center" in:fade>
              <div class=" lg:px-8 text-center relative w-full background bg-cover" style="background-image: url('{AscensionBlockPlain}');background-size: 100% 100%">

                <div class="z-1 w-full px-[10%] py-[8%] sm-px-20 lg:px-[4%] lg:py-[12%] xl:px-[2%] xl:py-[12%] relative">

                  {#if moreKitsune}

                    <p>
                      It looks like you have
                    </p>
                    <h3 class="text-xl sm:text-2xl font-bold main-font mb-2 lg:mb-8">
                      <span class="gold-color">{kitsuneAvailable}</span> KITSUNE
                    </h3>

                    <p>
                      Would you like to claim more?
                    </p>

                    <p class="hidden lg:block text-xs md:text-sm text-gray-400">
                      Maximum 20 per transaction
                    </p>

                  {:else if confirmMultipleClaim || confirmClaim}

                    <p>
                      Are you sure you want to claim
                    </p>
                    <h3 class="text-xl sm:text-2xl font-bold main-font mb-2 mt-4 lg:mb-8 px-12">

                      {#if confirmMultipleClaim}
                        <span class="gold-color">{kitsuneMutlipleCount}</span> KITSUNE ?
                      {:else}
                        KITSUNE FOR RONIN <span class="gold-color">#{roninID}</span> ?
                      {/if}
                    </h3>

                    <p class="hidden lg:block text-xs md:text-sm text-gray-400">
                      You cannot undo this action
                    </p>

                  {:else if errorProcessing}

                    <h3 class="text-xl sm:text-2xl font-bold main-font mb-0 mt-4 lg:mb-8 px-12">
                      UH OH !
                    </h3>
                    <p class="mb-16">
                      Something went wrong with the transaction
                    </p>

                  {/if}

                  <div class="relative w-full background bg-cover mt-10" style="background-image: url('{TicketBox}');background-size: 100% 100%">
                    <div class="relative py-[6%] sm:py-[8%] lg:py-[6%] xl:py-[6%] 2xl:py-[6%] z-40 text">

                        <div class="">
                          <div class="mt-2 md:mt-4 flex flex-col items-center">

                            {#if moreKitsune}

                              <button class="gold-background text-sm px-4 py-2 xl:py-3 rounded-full w-3/5 text-sm lg:text-base mb-3 text-black" on:click={clickClaimMultiple}>
                                CLAIM {kitsuneMutlipleCount} AT ONCE
                              </button>
                              <button class="bg-[#5f7b5b] text-sm px-4 py-2 xl:py-3 rounded-full w-3/5 text-sm lg:text-base mb-3" on:click={clickClaimJustOne}>
                                CLAIM FOR 1
                              </button>
                              <button class="bg-[#ba5145] text-sm px-4 py-2 xl:py-3 rounded-full w-3/5 text-sm lg:text-base mb-3" on:click={clickCancel}>
                                CANCEL
                              </button>

                            {:else if confirmMultipleClaim || confirmClaim}
                              {#if !processingClaim}

                                <button class="bg-[#5f7b5b] text-sm px-4 py-2 xl:py-3 rounded-full w-1/2 text-sm lg:text-base mb-3" on:click={clickConfirm}>
                                  CLAIM
                                </button>
                                <button class="bg-[#ba5145] text-sm px-4 py-2 xl:py-3 rounded-full w-1/2 text-sm lg:text-base mb-3" on:click={clickCancel}>
                                  CANCEL
                                </button>

                              {:else}

                                <p class="py-12">
                                  Processing...
                                </p>

                              {/if}

                            {:else if errorProcessing}

                              <p class="text-red-500 font-bold">Error</p>
                              <p class="text-sm">
                                The transaction has been cancelled.
                              </p>
                              <button class="gold-background text-sm px-4 py-2 xl:py-3 rounded-full w-1/2 text-sm lg:text-base mb-3 text-black mt-6" on:click={clickRetry}>
                                TRY AGAIN
                              </button>

                            {/if}

                          </div>
                        </div>

                      <!-- {/if} -->
                    </div>
                  </div>
                </div>
              </div>

            </div>
          {/if}

        <!-- Claimed -->
        {:else}

          <div class="mx-1 mt-12 -mb-40 mx-6 md:mx-6 lg:mx-8 xl:mx-36">
            <div class=" lg:px-12 text-center relative w-full background bg-cover" style="background-image:
            url('{KitsuneClaimedBlock}');background-size: 100% 100%">

              <div class="z-1 w-full px-[10%] py-[10%] sm-px-20 lg:px-[4%] lg:py-[12%] xl:px-[2%] xl:py-[12%] relative">

                <div class="max-w-full justify-center flex items-center">
                  {#if kitsuneImageUrl}
                    <img src={kitsuneImageUrl} alt="Kitsune" class="mx-auto w-full relative z-10 mb-2 bg-black bg-opacity-50"/>
                  {:else}

                  {/if}
                </div>


                {#if claimedMultiple}

                {:else}
                  <h1 class="block ascension_id mb-0 text-sm py-1 text-gray-400">Kitsune #{kitsuneID}</h1>
                {/if}

                <div class="relative w-full background bg-cover" style="background-image: url('{TicketBox}');background-size: 100% 100%">
                  <div class="relative py-[8%] sm:py-[10%] lg:py-[10%] z-40 text-sm md:text-base">

<!--                    <div>{kitsuneID},{$metadata[kitsuneID]},-->
<!--                      {#if $metadata[kitsuneID]}-->
<!--                        {$metadata[kitsuneID].state},-->
<!--                      {/if}-->
<!--                      {kitsuneImageUrl}</div>-->

                    <p class="w-4/5 mx-auto">
                      You have succesfully claimed<br>



                      {#if claimedMultiple}

                        <span class="gold-color font-bold">{kitsuneClaimed}</span> <span class="font-bold">Kitsune!</span>

                      {:else}

                        <span class="gold-color font-bold">Kitsune #{kitsuneID}!</span>

                      {/if}
                    </p>

                  </div>
                </div>
              </div>
            </div>

            <div class="block">
              <div class=" mt-4">
                {#if claimedMultiple}

                  <button class="gold-background px-6 py-2 mt-2 lg:mt-6 secondary-font text-black rounded-full text-xs sm:text-base cursor-pointer" on:click={forwardToProfile}>VIEW YOUR NFTs</button>

                {:else}

                  <button class="gold-background px-6 py-2 mt-2 lg:mt-6 secondary-font text-black rounded-full text-xs sm:text-base cursor-pointer" on:click={forwardToProfile}>VIEW YOUR KITSUNE</button>

                {/if}

              </div>
            </div>
          </div>

        {/if}
      </div>
    </div>
  </div>


</div>

<div  class="container mx-auto sm:max-w-screen-sm lg:max-w-screen-2xl text-xs lg:text-base px-8 sm:px-24 md:px-10 lg:px-0 mt-24 lg:mt-28 text-white">
  <TokenSearch isKitsune={true} />
</div>
