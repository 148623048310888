<script>
    /* ---- SECTIONS ---- */
    import OpeningIntro from '../components/sections/OpeningIntro.svelte';
    import FrontPageIntro from '../components/sections/FrontPageIntro.svelte';
    import AscensionIntro from '../components/sections/AscensionIntro.svelte';
    import KitsuneIntro from '../components/sections/KitsuneIntro.svelte';
    import JiseiIntro from '../components/sections/JiseiIntro.svelte';
    import Tokenomics from '../components/sections/Tokenomics.svelte';
    import CloudTransition from '../components/shared/CloudTransition.svelte';
    import ClaimAlert from '../components/shared/ClaimAlert.svelte';
    import ComicPopup from '../components/ComicPopup.svelte'; // <-- Added this line
    import {setKitsuneScroll} from '../stores/ui';

    import BattlegroundLayer from '../components/shared/BattlegroundLayer.svelte';

    import {FogTop, FogMid, FogBottom} from '../../public/assets/urls';

    import {onMount} from 'svelte';

    let screenWidth;
    let screenHeight;
    let link;
    let iPhoneSE, iPhoneX, iPhone12, SGalaxy;

    $: {
        screenWidth < 500 && screenHeight < 670 ? iPhoneSE = true : iPhoneSE = false;
		screenWidth < 500 && screenHeight > 670 && screenHeight < 750 ? SGalaxy = true : SGalaxy = false;
		screenWidth < 500 && screenHeight > 670 && screenHeight < 750 ? SGalaxy = true : SGalaxy = false;

        screenWidth < 500 && screenHeight > 670 && screenHeight < 750 ? SGalaxy = true : SGalaxy = false;

		screenWidth < 500 && screenHeight > 751 && screenHeight < 850 ? iPhone12 = true : iPhone12 = false;
		screenWidth < 500 && screenHeight > 751 && screenHeight < 850 ? iPhone12 = true : iPhone12 = false;

        screenWidth < 500 && screenHeight > 751 && screenHeight < 850 ? iPhone12 = true : iPhone12 = false;

        screenWidth < 500 && screenHeight > 851 && screenHeight < 896 ? iPhoneX = true : iPhoneX = false;
    }

    $: {
        iPhoneSE ? console.log('iphoneSE') : SGalaxy ? console.log('Sgalaxy') : iPhone12 ? console.log('iphone 12') : iPhoneX ? console.log('iphoneX') : null
    }

    $: link = $setKitsuneScroll;

    onMount(() => {
        scrollTo(0,0);
        if (link === true) {
            setTimeout(() => {
                document.getElementById('04_Kitsune').scrollIntoView(true);
                window.scrollBy(0, -130);
            } , 500);
        }
    });
</script>

<svelte:window bind:innerHeight={screenHeight} bind:innerWidth={screenWidth}/>

<svelte:head>
    <!-- Hotjar Tracking Code for https://onironin.io/ -->
    <script> 
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){
                (h.hj.q=h.hj.q||[]).push(arguments);
            };
            h._hjSettings={hjid:2922448,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');
            r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    </script>
</svelte:head>

<div class="general_layout {screenWidth < 600 ? "overflow-hidden":""}">
    <ComicPopup />
    {#if screenWidth < 600}
        <BattlegroundLayer/>
    {/if}
    <div class="intro_screen {iPhoneSE === true ? "-mb-10": SGalaxy === true ? "" : iPhone12 === true ? "mt-10": iPhoneX ? "mt-10":""}">
        <OpeningIntro/>
    </div>
    {#if screenWidth > 500} 
        <div class="section_1_intersection"></div>
        <div class="section_2_intersection fog_container">
            <CloudTransition/>
        </div>
    {/if}

	<div id="01_Intro" class="mb-96 {iPhoneSE === true ? "mt-8": SGalaxy === true ? "" : iPhone12 === true ? "": iPhoneX ? "":""}">
		<FrontPageIntro/>
	</div>
	<!-- <div class="relative w-full z-10">
		<img src={FogBottom} class="block h-96 sm:h-1/6 w-full opacity-50 fog-overlay "/>
	</div> -->

	<div id="02_Ascension" class="mb-96 ">
		<AscensionIntro/>
	</div>
	<!-- <div class="relative w-full z-10">
		<img src={FogBottom} class="block h-96 sm:h-1/6 w-full opacity-50 fog-overlay"/>
	</div> -->

	<div id="03_Jisei" class="mb-96 {iPhoneSE === true ? "mt-8": SGalaxy === true ? "mt-8" : iPhone12 === true ? "mt-10": iPhoneX ? "":""}">
		<JiseiIntro/>
	</div>
	<!-- <div class="relative w-full z-10">
		<img src={FogBottom} class="block h-96 sm:h-1/6 w-full opacity-50 fog-overlay"/>
	</div> -->


	<div id="04_Kitsune" class="mb-96 {iPhoneSE === true ? "mt-8": SGalaxy === true ? "mt-8" : iPhone12 === true ? "mt-12": iPhoneX ? "mt-12":""}">
		<KitsuneIntro/>
	</div>
	<!-- <div class="relative w-full z-10">
		<img src={FogBottom} class="block h-96 sm:h-1/6 w-full opacity-50 fog-overlay"/>
	</div> -->

	<div id="05_Tokenomics" class="mb-32 {iPhoneSE === true ? "mt-8 ": SGalaxy === true ? "mt-8" : iPhone12 === true ? "mt-10": iPhoneX ? "":""}">
		<Tokenomics/>
	</div>
	<!-- <div class="relative w-full z-10">
		<img src={FogBottom} class="block h-96 sm:h-1/6 w-full opacity-50 fog-overlay"/>
	</div> -->
</div>

<style>

    .fog-overlay {
        margin-top: -25%;
    }
</style>