<script>
    import {fade} from 'svelte/transition';
    import {profile} from "../../stores/metadata";

    import {get} from "svelte/store";
    import {toggleZoom, setEnhancement} from '../../stores/ui';

    import {metadata, selectedKitsune,OPENSEA_STEM} from "../../stores/kitsuneMetadata";


    function capitalise(_string){
        if(_string.length === 0){
            return "";
        }else if(_string.length === 1){
            return _string.toUpperCase();
        }else{
            return _string[0].toUpperCase() + _string.substr(1);
        }
    }


    let tokenId = get(selectedKitsune);

    let _metadata = get(metadata)[tokenId];

    let img      = _metadata.name;
    let name     = _metadata.image;
    let traits   = _metadata.attributes;

    $:{
        tokenId = $selectedKitsune;

        if($metadata[tokenId]){
            _metadata = $metadata[tokenId];

            name = _metadata.name;
            img = _metadata.image;
            traits = _metadata.attributes;
        }

    }

    let zoom = false;
    $: $setEnhancement === true ? zoom = true : zoom = false;




    function handleZoom(){
        toggleZoom();
    }



    const newWindow = (url) => { window.open(url) };

    const clickForOpenSea = () => { newWindow( OPENSEA_STEM + tokenId ) };

    // const handleZoom = () => { toggleZoom() }


    let w;

    let urlLoaded = false;

    const updateWindowURL = () => {
        window.history.replaceState
        (
            {},
            `Oni Ronin - Kitsune ${tokenId}`,
            `/kitsuneDetail/${tokenId}`
        )
        urlLoaded = true;
    }
    $: urlLoaded === false ? updateWindowURL() : null


</script>

<svelte:window bind:innerWidth={w}/>


<div class="fog_container profile_container" in:fade>
    <div class="mt-48 {w > 600 ? 'profile_columns':'profile_rows'}">
        <div class="{w > 600 ? 'left_col': 'left_col_mobile mt-32'}">
            {#if w < 600}
                <h1 class="ronin_title_mobile">KITSUNE</h1>

                <h1 class="section_highlight section_tagline_center mb-5 animate-pulse duration-1000">
                    #{tokenId}
                </h1>
            {/if}
            <img id="currentRonin" src={img} alt={name} class="{w > 600 ? 'ronin_img':'ronin_img_responsive'}{zoom ? 'ronin_img_hidden':''}" on:click={toggleZoom} />

			<!-- Modal -->
			<div id="myModal" class="{zoom ? 'modal_open': 'modal'}">
			<img class="modal-content" id="img01" src={img} alt={name} on:click={handleZoom}>
            <!-- Modal Ends -->

        </div>
    </div>
    <div class="right_col text-left">
        {#if w > 600}
            <h1 class="ronin_title">KITSUNE</h1>

            <h1 class="section_highlight section_tagline animate-pulse duration-1000">
                #{tokenId}
            </h1>
        {/if}
        {#if w > 600}
            <p class="fine_print text-sm text-white-600 animate-pulse duration-1000">Available Actions</p>

                <div class="-ml-3">
                    <button on:click={clickForOpenSea} class="base-btn-asc-alt hover:animate-pulse duration-1000">VIEW ON OPENSEA</button>
                    <button on:click={toggleZoom} class="base-btn-asc-alt-mobile">ENHANCE</button>
                </div>

        {:else}
            <div class="text-center profile_controls_mobile mb-5">
                <p class="fine_print text-center text-sm text-white-600 animate-pulse duration-1000 -mt-5">Available Actions</p>
                <button on:click={toggleZoom} class="base-btn-asc-alt-mobile">ENHANCE</button>
                <button on:click={clickForOpenSea} class="base-btn-asc-alt-mobile">VIEW ON OPENSEA</button>
            </div>
        {/if}
        {#if traits}
            <div class="{w > 600 ? 'attr':'mobile_attr'} -mt-10">
				{#each traits as attributes}
                    <p class="mb-1 {w > 600 ? '':''}">
                        <span class="attr_type mr-1">{capitalise(attributes.trait_type)}:</span>
                        <span class="attr_value">{attributes.value}</span>
                    </p>
                {/each}
            </div>
        {/if}
    </div>
</div>
</div>

<style>

    .ronin_img {
        height: 500px;
        width: 500px;
        margin: 0 auto;
        z-index: 5;
        outline: 2px solid gold;
        background: black;
    }

    .left_col {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-bottom: 1em;
        margin-right: 2em;
        height: 500px;
        width: 500px;
    }
    .left_col_mobile {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-bottom: 1em;
        height: 500px;
        width: auto;
    }
    .right_col {
        align-items: center;
        justify-content: center;
    }

</style>