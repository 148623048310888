<script>
	import {onMount} from 'svelte';
	import {useAlchemy, state, data} from '../stores/manager'
	import {fade} from 'svelte/transition';
	import HazeLayer from '../components/shared/HazeLayer.svelte'
	import {fetchIPFSProfile,fetchProfile, profile, profiles} from '../stores/metadata.js';
	import {mountedForAscension, identifyRonin, isGold} from '../stores/transactions';
	import {toggleZoom, setEnhancement} from '../stores/ui'
	import Spinner from '../components/shared/Spinner.svelte';
	import BattlegroundLayer from '../components/shared/BattlegroundLayer.svelte';

	import {navigateTo} from 'svelte-router-spa';

	export let currentRoute;

	import { KitsuneUnclaimed } from '../../public/assets/urls';

	import {claimable} from "../stores/manager";


	let w;
	let zoom = false;
	let roninId = currentRoute.namedParams.id;
	let readyProfile = false;

	let fallen = false;
	let golden = false;

	let owner;

	let myRonins = $data.myRonins.value;

	let token;
	let inWallet = myRonins.includes(Number(roninId))

	let opensea = process.env.OPENSEA_SHARE;

	const newWindow = (url) => { window.open(url) };

	const handleZoom = () => { toggleZoom() }

	$:roninId > 10000 ? token = roninId - 10000 : token = roninId


	let canClaimKitsune = false;
	$:{
		canClaimKitsune = $data.unclaimedKitsunes.value.length > 0 && $data.unclaimedKitsunes.value.includes(parseInt(roninId));
	}
	let isMyKitsune = false;
	$:{
		isMyKitsune = $data.myRonins.value.includes(parseInt(roninId));
	}

	function claimKitsuneClick(){
		navigateTo("kitsune/"+roninId)
	}


	$: {
			if (myRonins !== []) {
				if (inWallet === true) {
					owner = true;
				} else {
					owner = false;
				}
			}
		}

	const getRoninData = async (token) => {
		// fetchProfile(token)
		fetchIPFSProfile(token)
		// setTimeout(() => readyProfile = true, 1000)
	}


	$:{
		readyProfile = $profiles.trueID === roninId && $profiles.state === "loaded";
	}

	const returnToCollection = () => {
		navigateTo('yournfts')
	}

	const selectForAscension = () => {
		mountedForAscension()
		navigateTo('/ascend')
		identifyRonin(profile.name)
	}

	const clickForOpenSea = () => { newWindow( `${opensea}`+ profile.trueID ) };

	onMount(() => {
		getRoninData(roninId)
	})

	$ : !roninId ? setTimeout(() => getRoninData(roninId), 2000) : null;

	$: $setEnhancement === true ? zoom = true : zoom = false;

	$: if ($profiles.trueID > 10000) {
			$isGold ? golden = true : fallen = true;
	}

	let isActuallyGold = false;
	$: {
		if($profiles && $profiles.traits ){
			isActuallyGold = false;
			for(let i = 0; i < $profiles.traits.length; i++){
				let trait = $profiles.traits[i];
				if(trait.trait_type === "Soul" && trait.value === "金色の浪士"){
					isActuallyGold = true;
					break;
				}
			}
		}
	}

	let kitsuneCheckReady = false;
	let kitsuneAvailable = false;
	let kitsuneCheckDone = false;

	async function checkClaimable(){
		kitsuneCheckDone = true;
		kitsuneAvailable = await claimable(roninId);
	}

	$:{
		kitsuneCheckReady = $data.unclaimedKitsunes.loaded;

		if(kitsuneCheckReady && !kitsuneCheckDone){
			checkClaimable();
		}
		//
		// kitsuneAvailable = $data.unclaimedKitsunes.value.includes(roninId);
	}

</script>

<svelte:window bind:innerWidth={w}/>

<svelte:head>
	<!-- Hotjar Tracking Code for https://onironin.io/ --> 
	<script> 
		(function(h,o,t,j,a,r){
			h.hj=h.hj||function(){
				(h.hj.q=h.hj.q||[]).push(arguments)
				};
				h._hjSettings={hjid:2922448,hjsv:6};
				a=o.getElementsByTagName('head')[0];
					r=o.createElement('script');
					r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);
					})
				(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
	</script>
</svelte:head>

<div class="general_layout">

	<HazeLayer/>
	<BattlegroundLayer/>

	{#if readyProfile === true}
	<div class="fog_container profile_container" in:fade>
		<div class="profile_nav_row">
<!--			CURRENT VERSION-->
<!--			=== {canClaimKitsune} === {$data.unclaimedKitsunes.value.length} === { $data.unclaimedKitsunes.value} === {token}-->
<!--			&lt;!&ndash; <h1 class="profile_title mx-auto mt-20 cursor-pointer" on:click={returnToCollection}>YOUR NFTs</h1> &ndash;&gt;-->
		</div>



		<div class="{w > 600 ? 'profile_columns':'profile_rows'}">
			<div class="{w > 600 ? 'left_col':'left_col_mobile mt-32'}">
				{#if w < 600}

					{#if isActuallyGold}
						<h1 class="ronin_title_mobile -mb-4">GOLDEN</h1>
						<h1 class="ronin_title_mobile -mt-5">RONIN</h1>
					{:else if fallen}
						<h1 class="ronin_title_mobile -mb-4">FALLEN</h1>
						<h1 class="ronin_title_mobile -mt-5">RONIN</h1>
					{:else}
						<h1 class="ronin_title_mobile">RONIN</h1>
					{/if}

						<h1 class="section_highlight section_tagline_center mb-5 animate-pulse duration-1000">
							#{token}
						</h1>
			{/if}
				<div>
					<img id="currentRonin" src={$profiles.url} alt={$profiles.name} class="{w > 600 ? 'ronin_img':'ronin_img_responsive'}{zoom === true ? "ronin_img_hidden":""}" on:click={handleZoom}/>

					{#if canClaimKitsune}
						<img src="{KitsuneUnclaimed}" class="h-9 w-9 relative bottom-10 right-1" style="float: right"  />
					{/if}

					<div style="background: none; margin-top:60px; display: flex; flex-direction: row; justify-content: center; align-items: center; font-size: 14px;">
						{#if kitsuneCheckReady}
							{#if kitsuneAvailable}
								<!--{#if canClaimKitsune}-->

									<span style="background: none;">
										<img src="{KitsuneUnclaimed}" class="h-9 w-9 bottom-1"  />
									</span>
									<span style="padding-left: 10px;">
										Kitsune Available for Claim
									</span>
							{:else}

									Kitsune NOT Available for Claim with this Ronin
							{/if}
						{/if}
					</div>

</div>
		<!-- Modal -->
			<div id="myModal" class="{zoom === true ? "modal_open": "modal"}">
				<img class="modal-content" id="img01" src={$profiles.url} alt={$profiles.name} on:click={handleZoom}>
				<div id="caption">
					<!-- <p class="section_content_center">Pinch To Zoom</p> -->
				</div>
		<!-- Modal Ends -->
			</div>
			</div>
			<div class="right_col text-left">
				{#if w > 600}
					{#if isActuallyGold}
						<h1 class="ronin_title -mb-9">GOLDEN</h1>
						<h1 class="ronin_title -mt-5">RONIN</h1>
					{:else if fallen}
						<h1 class="ronin_title -mb-9">FALLEN</h1>
						<h1 class="ronin_title -mt-5">RONIN</h1>
					{:else}
						<h1 class="ronin_title">RONIN</h1>
					{/if}

					<h1 class="section_highlight section_tagline animate-pulse duration-1000">
						#{token}
					</h1>
				{/if}
				{#if w > 600}
					<p class="fine_print text-sm text-gray-600 animate-pulse duration-1000">Available Actions</p>
						{#if $profiles.trueID < 9999}
							<div class="-ml-3 -mb-2">
								{#if owner}
									<button class="base-btn-asc -px-2 hover:animate-pulse duration-1000" on:click={selectForAscension}>ASCEND</button>
								{/if}

								<button on:click={toggleZoom} class="base-btn-asc-alt-mobile">ENHANCE</button>

								{#if canClaimKitsune && isMyKitsune}
									 <button class="base-btn-asc hover:animate-pulse duration-1000"  on:click={claimKitsuneClick}>CLAIM KITSUNE</button>
								{/if}
							</div>
							<div class="-ml-3 mb-10">
								<button on:click={clickForOpenSea} class="base-btn-asc-alt hover:animate-pulse duration-1000">VIEW ON OPENSEA</button>
							</div>
					{:else}
						<div class="-ml-3">
							{#if canClaimKitsune && isMyKitsune}
							 <button class="base-btn-asc hover:animate-pulse duration-1000" on:click={claimKitsuneClick}>CLAIM KITSUNE</button>
							{/if}
							<button on:click={clickForOpenSea} class="base-btn-asc-alt hover:animate-pulse duration-1000">VIEW ON OPENSEA</button>
							<button on:click={toggleZoom} class="base-btn-asc-alt-mobile">ENHANCE</button>
						</div>
					{/if}
				{:else}
				<div class="text-center profile_controls_mobile mb-10">
					<p class="fine_print text-center text-sm text-gray-600 animate-pulse duration-1000 -mt-8">Available Actions</p>
					{#if $profiles.trueID < 9999}
						{#if owner}
							<button class="base-btn-asc-mobile" on:click={selectForAscension}>ASCEND</button>
						{/if}
					{/if}
					{#if canClaimKitsune && isMyKitsune}
					 <button class="base-btn-asc-mobile"  on:click={claimKitsuneClick}>CLAIM KITSUNE</button>
					{/if}
					<button on:click={toggleZoom} class="base-btn-asc-alt-mobile">ENHANCE</button>
					<button on:click={clickForOpenSea} class="base-btn-asc-alt-mobile">VIEW ON OPENSEA</button>
				</div>
				{/if}
				{#if $profiles.traits}
					<div class="{w > 600 ? "attr":"mobile_attr"} -mt-10">
							{#each $profiles.traits as attributes}
								<p class="mb-1 {w > 600 ? '':''}">
									<span class="attr_type mr-1">{attributes.trait_type}:</span>
									<span class="attr_value">{attributes.value}</span>
								</p>
							{/each}
					</div>
				{/if}



			</div>
		</div>


	</div>
	{/if}
</div>

<style>

	.ronin_img {
		height: 500px;
		width: 500px;
		margin: 0 auto;
		z-index: 5;
		outline: 2px solid gold;
		background: black;
	}

	.left_col {
		display: flex;
		flex-direction: column;
		align-self: center;
		margin-bottom: 1em;
		margin-right: 2em;
		height: 500px;
		width: 500px;
	}
	.left_col_mobile {
		display: flex;
		flex-direction: column;
		align-self: center;
		margin-bottom: 1em;
		height: 500px;
		width: auto;
	}
	.right_col {
		align-items: center;
		justify-content: center;
	}

</style>