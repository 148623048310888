<script>
  import {mintRonin, mintBento, useAlchemy,transacting} from '../stores/manager';
  import {unlock} from '../stores/web3/wallet';
  import {mint_bg, mint_ticket} from '../../public/assets/urls';
  import HazeLayer from '../components/shared/HazeLayer.svelte';
  import SuccessLayer from '../components/shared/SuccessLayer.svelte';

  import TokenSearch from '../components/TokenSearch.svelte';


  import { AscensionBlockFire, TicketBox } from '../../public/assets/urls';
  import {fade} from 'svelte/transition';
  import {onMount} from 'svelte'
  import {get} from 'svelte/store'
  import {navigateTo} from 'svelte-router-spa'
  import {
    MINT_ERROR,
    MINT_FAILURE,
    MINT_PENDING,
    MINT_SUCCESS,
    initializeMint,
    launchMint,
    completedMint,
    resetMintState,
    _hash
  } from '../stores/transactions';

  onMount(() => {
    scrollTo(0,0);
  })

  let left = "-";
  let right = "+";
  let quantity = 1;

  let count;

  let one_position;
  let ten_position;

  let min = false;
  let max = false;
  let forceConnect;

  let hash;

  let etherscan = `https://etherscan.io/tx/`;

  let newWindow = (etherscan, hash) => {
    window.open(etherscan + hash);
  };

  let w; /* Important for Tracking Responsive Width */

  let _success, _pending, _failure, _error;

  const increment = () => {
    if(get(transacting).mint) return;
    quantity++
  };
  const decrement = () => {
    if(get(transacting).mint) return;
    quantity--
  };

  const handleMint = (quantity) => {
    if ($useAlchemy === false) {
      launchMint()
      // mintRonin(quantity)
      mintBento(quantity)
    } else if ($useAlchemy === true) {
      forceConnect = true;
      unlock()
    }
  }

  const viewCollection = () => {
    resetMintState();
    navigateTo('yournfts')
  }

  $: {
    _error = $MINT_ERROR,
    _failure = $MINT_FAILURE,
    _pending = $MINT_PENDING,
    _success = $MINT_SUCCESS
  }

  $: {
    $useAlchemy === true ? forceConnect = true : forceConnect = false
  }

  $: {
    if (quantity <= 1) {
      quantity = 1
      min = true
    } else if (quantity > 1) {
      min = false
    }
  };

  $: {
    if (quantity > 9 && quantity >= 10) {
      max = true
      quantity = 10
    } else if (quantity < 10) {
      max = false
    }
  };

  $: {
    if (quantity <= 9) {
      count = '0' + `${quantity}`

      ten_position = `0`
      one_position = `${quantity}`
    } else if (quantity === 10) {
      count = `10`
      ten_position = `1`
      one_position = `0`
    }
  }


  $: {
    if (_pending === true && typeof _hash === 'string') {
      hash = _hash
    }
  }
</script>

<svelte:window bind:innerWidth={w} />

<svelte:head>
  <!-- Hotjar Tracking Code for https://onironin.io/ -->
  <script>
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){
        (h.hj.q=h.hj.q||[]).push(arguments)
        };
        h._hjSettings={hjid:2922448,hjsv:6};
        a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');
          r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);
          })
        (window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

  </script>
</svelte:head>

<div class="relative">
  <div class="absolute top-0 left-0">
    <!-- Background Layers Begins -->
    {#if _success === true}
      <SuccessLayer/>
    {:else}
      <HazeLayer/>
    {/if}
    <!-- Background Layers Ends -->
  </div>

  <div class="container mx-auto px-10 sm:px-11 md:px-2 lg:px-14 xl:px-40 2xl:px-72 mt-28">
    <div class="grid grid-cols-1 content-top md:text-left">
      {#if _success === true}
        <div class="text-center text-white z-10 mt-8 mb-10">
          <p class="plain-font">
            Oni Ronin are a collection of 8,888 Demonic Samurai living in eternal dishonor on the Ethereum blockchain.
            <br>
            <span class="font-bold gold-color">Mint now to claim your own Oni Ronin</span>
          </p>
        </div>
      {:else}
        <div class="block md:hidden text-white text-center">
          <h1 class="title-font text-5xl font-bold mb-1">
            MINT
          </h1>
          <h2 class="title-font text-l mb-2">
            YOUR RONIN
          </h2>
        </div>
      {/if}

      <div class="grid content-left  {_success ? 'cols-1 md:grid-cols-5 lg:grid-cols-7' : 'grid-cols-1 md:grid-cols-2'}">
        <div class="text-white text-center relative {_success && 'md:col-start-2 md:col-span-3 lg:col-start-3'}">
          <img src={AscensionBlockFire} class="w-full -z-1 sm:px-8 md:px-0"/>
          <div class="absolute top-10 left-0 px-2 md:px-5 lg:mt-6 z-1 w-full">

            <div class="grid grid-cols-5 items-center justify-center p-4 sm:p-12 md:p-8 xl:p-18 2xl:p-8">
              {#if _success === true}
                <div class="col-span-5 title-font text-4xl lg:text-4xl font-bold text-center mt-5 mb-4 w-full">SUCCESS</div>
              {:else}
                <button class="p-4 lg:p-5 bg-stone-900 leading-3 title-font font-bold left-control rounded-md justify-self-start" on:click={decrement}>{left}</button>
                <div class="col-span-3 flex justify-center">
                  <div class="flex mint-quantity text-center justify-center p-2 rounded-xl">
                    <div class="quantity-digit mr-1 py-4 px-2 rounded-full text-5xl lg:text-7xl">{ten_position}</div>
                    <div class="quantity-digit ml-1 py-4 px-2 rounded-full text-5xl lg:text-7xl">{one_position}</div>
                  </div>
                </div>
                <button class="p-4 lg:p-5 bg-stone-900 leading-3 title-font font-bold right-control rounded-md justify-self-end" on:click={increment}>{right}</button>
              {/if}

              <div class="col-span-5">
                <div class="mt-10 sm:mt-16 grid" style="background-image: url('{TicketBox}');background-size: 100% 100%">
                  <div class="flex flex-col items-center">

                    <!-- Initial State -->
                    {#if _pending === false && _error === false && _success === false}
                      <h3 class="animate-pulse duration-500 mint_limit w-full mb-2 lg:mb-4 text-xs mt-4 lg:mt-8">
                        Maximum : 10 per transaction
                      </h3>

                      <button class="mint-button px-12 lg:px-16 py-2 text-sm lg:text-base" on:click={handleMint(quantity)}>MINT NOW</button>

                      <h3 class="animate-pulse duration-500 mint_limit w-full mb-4 md:mb-8 text-xs mt-2 lg:mt-4">
                        Mint Price: 0.0888 ETH
                      </h3>
                    {/if}

                    <!-- Pending State -->
                    {#if _pending === true}
                      {#if hash}
                        <h3 class="text-lg w-full mt-6 lg:mt-10 mb-2 gold-color">
                          Pending...
                        </h3>

                        <p class="plain-font text-sm">
                          You can view your transaction on
                        </p>
                        <p class="text-sm mb-6 lg:mb-10">
                          <a href={etherscan + hash} alt="Your Transaction" target="_blank" class="gold-color font-bold">Etherscan</a>
                        </p>
                      {:else}

                        <h3 class="text-lg w-full mb-2 gold-color mb-12 mt-12 lg:mt-16 lg:mb-16">
                          Pending...
                        </h3>
                      {/if}
                    {/if}

                    <!-- Cancellation/Error State -->
                    {#if _error === true}
                      <h3 class="text-red-500 animate-pulse font-bold mb-0 mt-4 lg:mt-8">Error</h3>
                      <p class="plain-font text-xs">Your Transaction has been canceled.</p>
                      <button class="mint-button px-14 py-2 mt-3 mb-8 lg:mb-12 text-sm md:text-base" on:click={handleMint(quantity)}>TRY AGAIN</button>
                    {/if}

                    <!-- Success State -->
                    {#if _success === true}
                      <div class="text-base w-full mb-3  mt-4 lg:mt-8">
                        <div class="mb-0">Complete!</div>
                        <span class="font-bold gold-color text-sm">Successfully Minted {quantity} Oni Ronin!</span>
                      </div>
                      <button class="mint-button px-6 py-2 mb-8 lg:mb-12 text-sm md:text-base" on:click={viewCollection}>VIEW YOUR NFTs</button>
                    {/if}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mint Desktop Right Column Begins -->
        <div class="text-white text-left sm:ml-4 lg:ml-10 mt-4 px-2 md:-px-0">
          <div class="hidden md:block">
            <h1 class="title-font text-5xl font-bold mb-2">
              MINT
            </h1>
            <h2 class="title-font text-xl mb-4">
              YOUR RONIN
            </h2>
          </div>

          <p class="plain-font text-sm text-center md:text-base md:text-left">
            Oni Ronin are a collection of <span class="font-bold gold-color">8,888 demonic Samurai</span> living in eternal dishonor on the Ethereum blockchain.
          </p>
          <br>

          <p class="plain-font">
            <span class="font-bold gold-color">For each Ronin you mint, you will be able to claim one Kitsune fox spirit companion NFT.</span>
          </p>
          <br>

          <h2 class="title-font text-xl mb-2">
            COMMERCIAL RIGHTS
          </h2>

          <p class="plain-font">
            You get full commercial rights to every Ronin and Kitsune companion you mint, giving you the freedom to use them however you wish.
          </p>
          <br>

          <h2 class="title-font text-xl mb-2">
            ROADMAP
          </h2>
          <p class="plain-font">
             You also unlock an ongoing education program in Samurai history and culture, free companion NFTs, the chance to win prizes and real-world experiences, and join a passionate community of fellow Samurai enthusiasts.
          </p>
        </div>
        <!-- Mint Desktop Right Column Ends -->
      </div>
    </div>


  </div>

</div>

<div style="width:100%; display: flex; flex-direction: row; justify-content: center">
  <TokenSearch style="" />
</div>

<style>
  .title-font {
    font-family: var(--main-bold);
  }

  .plain-font {
    font-family: 'aaux-next';
  }

  .gold-color {
    color: #d1c179;
  }

  .mint-quantity {
    font-family: var(--main-bold);
    background-color: #1d1d1d70;
    border: 1px solid #1d1d1d80;
    overflow: visible;
    font-size: 4em;
    z-index: 0.5;
    letter-spacing: 0;
  }

  .quantity-digit {
    background-color: #00000030;
  }

  .mint-button {
    border: none;
    background-color: var(--light-gold);
    font-family: var(--secondary-font);
    font-weight: 400;
    letter-spacing: 0.2em;
    color: var(--jetblack);
    border-radius: 25px;
    z-index: 1;
  }
</style>
