<script>
	import SearchBar from '../components/SearchBar.svelte'
	import Profile from '../components/Profile.svelte'
	import {profiles} from '../stores/metadata'
</script>

<SearchBar/>
<Profile/>
<div class="mt-10">RESULTS</div>


