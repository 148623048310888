<script>
	import BattlegroundLayer from '../components/shared/BattlegroundLayer.svelte'
	import HazeLayer from '../components/shared/HazeLayer.svelte'

	import {onMount} from 'svelte';
	import {fade} from 'svelte/transition'

	/* ROADMAP IMAGE CELLS */
	import {
		BHCMembership,
		CommercialRights,
		SamuraiLessons,
		HaikuWorkshop,
		ZenMeditation,
		JapaneseTeaCeremony,
		FlowerArrangements,
		LanguageLessons,
		KitsuneCompanion,
		CustomOniRonin,
		OniRoninComics,
		AAPI,
		WhatsToCome,
		CustomMerch
	} from '../../public/assets/urls';

	import RoadmapMobile from './RoadmapMobile.svelte'

	onMount(() => { scrollTo(0,0) });

	let w;
	let screenHeight;
</script>

<svelte:window bind:innerWidth={w} bind:innerHeight={screenHeight}/>

<svelte:head>
	<!-- Hotjar Tracking Code for https://onironin.io/ --> 
	<script> 
		(function(h,o,t,j,a,r){
			h.hj=h.hj||function(){
				(h.hj.q=h.hj.q||[]).push(arguments)
				};
				h._hjSettings={hjid:2922448,hjsv:6};
				a=o.getElementsByTagName('head')[0];
					r=o.createElement('script');
					r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);
					})
				(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
	</script>
</svelte:head>

<div class="bg_wrapper">
	<BattlegroundLayer/>
	<HazeLayer/>
</div>
<div class="general_layout  mt-16 sm:mt-32" in:fade out:fade>
	{#if w > 800}

		<div class="mx-auto max-w-5xl w-full grid grid-cols-2 px-6">

			<!-- Left Column -->
			<div class="border-r-2 border-orange-300 relative float-right pr-16">

				<div class="relative mb-24">
					<h1 class="gold-color main-font text-5xl scale-90 font-bold mb-20 z-10 relative text-right">PERKS</h1>
					<h2 class="absolute top-1 left-0 text-neutral-700 text-8xl scale-90 -ml-10 font-bold main-font opacity-40">PERKS</h2>
				</div>

				<div class="relative mb-4">
					<img src={BHCMembership} alt="Bighead Club Membership" class="w-full relative z-10"/>
					<div class="absolute left-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-24 text-right">
					Owning Oni Ronin NFTs provides membership to the Big Head Club.
				</p>

				<div class="relative mb-4">
					<img src={SamuraiLessons} alt="Samurai History Lessons" class="w-full relative z-10"/>
					<div class="absolute left-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-24 text-right">
					Owning Oni Ronin NFTs grants access to multi-session lessons on the history of Samurai delivered by an accredited, University-level Professor of History.
				</p>

				<div class="relative mb-4">
					<img src={ZenMeditation} alt="Zen Meditation Workshops" class="w-full relative z-10"/>
					<div class="absolute left-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-24 text-right">
					Join guided zen meditation sessions from Tokozenji Temple. Awaken your true self and set out on the path to spiritual enlightenment.
				</p>

				<div class="relative mb-4">
					<img src={FlowerArrangements} alt="Flower Arrangement Classes" class="w-full relative z-10"/>
					<div class="absolute left-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-12 text-right">
					Enjoy an online class in the traditional Japanese art of flower arrangement. Samurai historically cherished this practice, each beautifully but ultimately temporary bouquet a reflection of their own mortality.
				</p>

				<div class="relative mb-20">
					<h1 class="gold-color main-font text-5xl scale-90 font-bold mb-20 -ml-16 z-10 relative text-left">MORE NFTS</h1>
					<h2 class="absolute top-0 right-0 text-neutral-700 text-8xl scale-90 font-bold main-font opacity-40">NFTS</h2>
				</div>

				<div class="relative mb-4">
					<img src={KitsuneCompanion} alt="Kitsune Companion" class="w-full relative z-10"/>
					<div class="absolute left-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-24 text-right">
					Every Ronin can claim a Kitsune fox spirit companion for free.
				</p>

				<div class="relative mb-4">
					<img src={OniRoninComics} alt="Oni Ronin Comics" class="w-full relative z-10"/>
					<div class="absolute left-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-12 text-right">
					We're working with Japanese writers and artists to create an original, beautifully-illustrated, manga-style comic book telling the story of Oni Ronin and the Trial of Ascension.
				</p>

				<div class="relative mb-20">
					<h1 class="gold-color main-font text-5xl font-bold mb-20 -ml-32 z-10 scale-90 relative text-right">MERCH & MORE</h1>
					<h2 class="absolute top-0 right-0 text-neutral-700 text-8xl scale-90 font-bold main-font opacity-40">MERCH</h2>
				</div>

				<div class="relative mb-4">
					<img src={CustomMerch} alt="Custom Merch" class="w-full relative z-10"/>
					<div class="absolute left-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-24 text-right">
					Members-only, limited-edition Oni Ronin merch.
				</p>

			</div>

			<!-- Right Column -->
			<div class="pl-16 relative">

				<h4 class="text-2xl font-bold main-font mt-4 mb-5 leading-6">ONI RONIN AREN'T<br> JUST COLLECTIBLES</h4>
				<p class="leading-5 mb-24">
					They also unlock perks, experiences, prizes, and a passionate community.
					<span class="gold-color">Here’s what the future holds for Oni Ronin owners:</span>
				</p>

				<div class="relative mb-4">
					<img src={CommercialRights} alt="Commercial Rights" class="w-full relative z-10"/>
					<div class="absolute right-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-24">
					As an Oni Ronin NFT holder, you own complete commercial rights to your NFT and the freedom to use it however you want.
				</p>

				<div class="relative mb-4">
					<img src={HaikuWorkshop} alt="Haiku Workshops" class="w-full relative z-10"/>
					<div class="absolute right-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-24">
					Join Haiku-writing workshops and learn the written art perfected by every Samurai.
				</p>

				<div class="relative mb-4">
					<img src={JapaneseTeaCeremony} alt="Japanese Tea Ceremony" class="w-full relative z-10"/>
					<div class="absolute right-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-24">
					Enjoy an authentic virtual tea ceremony hosted in a traditional tatami tea room in Kyoto by an expert Japanese tea master.
				</p>

				<div class="relative mb-4">
					<img src={LanguageLessons} alt="Japanese Language Lessons" class="w-full relative z-10"/>
					<div class="absolute right-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-24">
					The best way to understand a culture is to understand the language, so we're offering authentic Japanese language classes to bring NFT holders closer to their NFTs and their Haiku.
				</p>

				<div class="relative mb-4">
					<img src={CustomOniRonin} alt="Custom Oni Ronin" class="w-full relative z-10"/>
					<div class="absolute right-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-12">
					If you collect all ten soul shades of Fallen Ronin you can trade them in for a custom 1-of-1 Ronin designed by artist Maldoror based on your concept. Send your ten Fallen Ronin to RoninHell.eth and then email mack@bighead.club to claim. Four custom Ronin have already been created.
				</p>

				<div class="relative mb-20">
					<h1 class="gold-color main-font text-5xl font-bold mb-20 z-10 scale-90 relative text-left -ml-4">CHARITY</h1>
					<h2 class="absolute top-0 -left-2 text-neutral-700 text-8xl scale-90 font-bold main-font opacity-40">CHARITY</h2>
				</div>

				<div class="relative mb-4">
					<img src={AAPI} alt="Stop AAPI Hate" class="w-full relative z-10"/>
					<div class="absolute right-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-12">
					2% of primary sales goes to STOP AAPI Hate. To data, we've donated 6.620928 ETH (approximately $25k USD) to the organization.
				</p>

				<div class="relative mb-4">
					<img src={WhatsToCome} alt="Whats To Come" class="w-full relative z-10"/>
					<div class="absolute right-full top-1/2 w-16 h-2 z-20 border-b-2 border-orange-300"></div>
				</div>
				<p class="leading-5 mb-12">
					More raffles, giveaways, airdrops, perks, and exclusive offerings are coming for Oni Ronin holders.
				</p>

			</div>
		</div>

	{:else}
		<RoadmapMobile/>
	{/if}
</div>

<style>
	.border-faded-vertical-up {
		border-image: linear-gradient(to top, #d1c179, rgba(0, 0, 0, 0)) 1 100%;
		background-position: 5px 5px;
	}

.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background:#bab077;
  top: 250px;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.roadmap_container {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}

.roadmap_container.roadmap_left {
  left: 0;
}

.roadmap_container.roadmap_right {
  left: 50%;
}

/* .roadmap_container::after {
  content: '';
  position: absolute;
  width: 26px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #ffffff;
  border: 2px solid #fff;
  border-radius: 16px;
  z-index: 1;
} */

.roadmap_container.roadmap_right::after {
  left: -8px;
}

.roadmap_container::before {
  content: '';
  position: absolute;
  width: 150px;
  height: 2px;
  top: calc(50% - 1px);
  right: 1px;
  background: #bab077;
  z-index: 0.1;
}

.roadmap_container.roadmap_right::before {
  left: 1px;
}

.roadmap_container .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #006E51;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.roadmap_container.roadmap_left .date {
  right: -75px;
}

.roadmap_container.roadmap_right .date {
  left: -75px;
}

/* .roadmap_container .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #F6D155;
  border: 2px solid #006E51;
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: #006E51;
  z-index: 1;
} */

.roadmap_container.roadmap_left .icon {
  right: 56px;
}

.roadmap_container.roadmap_right .icon {
  left: 56px;
}

.roadmap_container .content {
  padding: 30px 90px 30px 30px;
  /* background: #F6D155; */
  position: relative;
  border-radius: 0 500px 500px 0;
	/* border: 2px solid blue; */
}

/* .roadmap_container.roadmap_right .content {
  padding: 30px 30px 30px 90px; */
  /* border-radius: 500px 0 0 500px; */
	/* border: 2px solid blue;
} */

/* FONTS / TYPOGRAPHY */
.roadmap_container .content h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: normal;
  color: #006E51;
}

.roadmap_container .content p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
	margin-top: 30px;
	margin-right: 1px;

}

.img_body {
	max-height: 100%;
	max-width: 100%;
}

@media (max-width: 767.98px) {
  .timeline::after {
    left: 90px;
  }

  .roadmap_container {
    width: 100%;
    padding-left: 120px;
    padding-right: 30px;
  }

  .roadmap_container.roadmap_right {
    left: 0%;
  }

  .roadmap_container.roadmap_left::after, 
  .roadmap_container.roadmap_right::after {
    left: 82px;
  }

  .roadmap_container.roadmap_left::before,
  .roadmap_container.roadmap_right::before {
    left: 100px;
    border-color: transparent #006E51 transparent transparent;
  }

  .roadmap_container.roadmap_left .date,
  .roadmap_container.roadmap_right .date {
    right: auto;
    /* left: 15px; */
  }

  .roadmap_container.roadmap_left .icon,
  .roadmap_container.roadmap_right .icon {
    right: auto;
    left: 146px;
  }

  .roadmap_container.roadmap_left .content,
  .roadmap_container.roadmap_right .content {
    padding: 30px 30px 30px 90px;
    border-radius: 500px 0 0 500px;
  }
}


</style>

