<script>
	import {
		BHCMembership,
		CommercialRights,
		SamuraiLessons,
		HaikuWorkshop,
		ZenMeditation,
		JapaneseTeaCeremony,
		FlowerArrangements,
		LanguageLessons,
		KitsuneCompanion,
		CustomOniRonin,
		OniRoninComics,
		AAPI,
		WhatsToCome,
		CustomMerch
	} from '../../public/assets/urls';
</script>



<div class="mobile_roadmap_header px-10">
	<h4 class="roadmap_title_mobile text-center mb-1">ONI RONIN</h4>
	<h4 class="roadmap_title_mobile_sm text-center -mt-3">AREN'T JUST COLLECTIBLES</h4>
	<p class="roadmap_content w-4/5 text-center mx-auto mt-1 text-xs">They also unlock perks, experiences, prizes, and a passionate community.</p>
	<p class="roadmap_content_alt text-center mx-auto mt-3 text-xs"> Here's what the future holds for Oni Ronin owners:</p>
</div>

<div class="mobile_roadmap_scrollview px-10">
	<div class="mt-2 mb-0 relative">
		<h1 class="roadmap_section_title text-right relative mb-12 relative z-10">PERKS</h1>
		<h2 class="absolute top-2 left-0 text-neutral-700 text-6xl font-bold main-font opacity-40">PERKS</h2>
		<div class="content">
			<img src={BHCMembership} alt="Bighead Club Membership" class="w-full"/>
			<p class="roadmap_content text-xs">
			Owning Oni Ronin NFTs provides membership to the Big Head Club.
			</p>
		</div>
	</div>
	<div>
		<div class="content">
			<img src={CommercialRights} alt="Commercial Rights" class="w-full"/>
			<p class="roadmap_content text-xs">
				As an Oni Ronin NFT holder, you own complete commercial rights to your NFT and the freedom to use it however you want.
			</p>
		</div>
	</div>
	<div>
		<div class="content">
			<img src={SamuraiLessons} alt="Samurai History Lessons" class="w-full"/>
			<p class="roadmap_content text-xs">
				Owning Oni Ronin NFTs grants access to multi-session lessons on the history of Samurai delivered by an accredited, University-level Professor of History.
			</p>
		</div>
	</div>
	<div class="">
		<div class="content">
			<img src={HaikuWorkshop} alt="Haiku Workshops" class="w-full"/>
			<p class="roadmap_content text-xs">
				Join Haiku-writing workshops and learn the written art perfected by every Samurai.
			</p>
		</div>
	</div>
	<div>
		<div class="content">
			<img src={ZenMeditation} alt="Zen Meditation Workshops" class="w-full"/>
			<p class="roadmap_content text-xs">
				Join guided zen meditation sessions from Tokozenji Temple. Awaken your true self and set out on the path to spiritual enlightenment.
			</p>
		</div>
	</div>
	<div>
		<div class="content">
			<img src={JapaneseTeaCeremony} alt="Japanese Tea Ceremony" class="w-full"/>
			<p class="roadmap_content text-xs">
				Enjoy an authentic virtual tea ceremony hosted in a traditional tatami tea room in Kyoto by an expert Japanese tea master.
			</p>
		</div>
	</div>
	<div>
		<div class="content">
			<img src={FlowerArrangements} alt="Flower Arrangement Classes" class="w-full"/>
			<p class="roadmap_content text-xs">

				Enjoy an online class in the traditional Japanese art of flower arrangement. Samurai historically cherished this practice, each beautifully but ultimately temporary bouquet a reflection of their own mortality.
			</p>
		</div>
	</div>
	<div class="">
		<div class="content">
			<img src={LanguageLessons} alt="Japanese Language Lessons" class="w-full"/>
			<p class="roadmap_content text-xs mb-0">
			The best way to understand a culture is to understand the language, so we're offering authentic Japanese language classes to bring NFT holders closer to their NFTs and their Haiku.
			</p>
		</div>
	</div>
	<div class="">
		<div class="content relative">
			<h1 class="roadmap_section_title mt-0 mb-12 relative text-left relative z-10">MORE NFTS</h1>
			<h2 class="absolute top-2 right-4 text-neutral-700 text-6xl font-bold main-font opacity-40">NFTS</h2>
			<img src={KitsuneCompanion} alt="KitsuneCompanion" class="w-full "/>
			<p class="roadmap_content text-xs">
				Every Ronin can claim a Kitsune fox spirit companion for free.
			</p>
		</div>
	</div>
	<div class="">
		<div class="content">
			<img src={CustomOniRonin} alt="Custom Oni Ronin" class="w-full"/>
			<p class="roadmap_content text-xs">
			If you collect all ten soul shades of Fallen Ronin you can trade them in for a custom 1-of-1 Ronin designed by artist Maldoror based on your concept. Send your ten Fallen Ronin to RoninHell.eth and then email mack@bighead.club to claim. Four custom Ronin have already been created.
			</p>
		</div>
	</div>
	<div class="">
		<i class="icon fa fa-user"></i>
		<div class="content">
			<img src={OniRoninComics} alt="Oni Ronin Comics" class="w-full"/>
			<p class="roadmap_content text-xs">
				We're working with Japanese writers and artists to create an original, beautifully-illustrated, manga-style comic book telling the story of Oni Ronin and the Trial of Ascension.
			</p>
		</div>
	</div>
	<div class="relative">
		<h1 class="roadmap_section_title text-right mt-0 mb-10 relative z-10 relative">CHARITY</h1>
		<h2 class="absolute top-2 right-0 text-neutral-700 text-5xl font-bold main-font opacity-40">CHARITY</h2>
		<div class="content">
			<img src={AAPI} alt="AAPI" class="img_body"/>
			<p class="roadmap_content text-xs">
				2% of primary sales goes to Stop AAPI Hate. To date, we've donated 6.620928 ETH (approximately $25k USD) to the organization.
			</p>
		</div>
	</div>
	<div class="relative">
		<h1 class="roadmap_section_title text-right mt-0 mb-16 -ml-16 relative text-base reltive z-10">MERCH & MORE</h1>
		<h2 class="absolute top-0 right-0 text-neutral-700 text-6xl font-bold main-font opacity-40">MERCH</h2>
		<div class="content">
			<img src={CustomMerch} alt="Custom Merch" class="w-full"/>
			<p class="roadmap_content text-xs">
				Members-only, limited-edition Oni Ronin merch.
			</p>
		</div>
	</div>
	<div class="">
		<div class="content">
			<img src={WhatsToCome} alt="COMING UP NEXT" class="w-full"/>
			<p class="roadmap_content text-xs">
				More raffles, giveaways, airdrops, perks, and exclusive offerings are coming for Oni Ronin holders.
			</p>
		</div>
	</div>
	<div class="spacer"></div>
</div>

<style>
	.content {
		margin: 0 auto;
		position: relative;
		/* border: 2px solid blue; */
		text-align: center;
	}

	.content p {
		font-size: 12px;
		margin: 0 auto;
		margin-top: 12px;
		margin-bottom: 32px;
	}

	.img_body {
		max-height: 100%;
		max-width: 300px;
		margin: 0 auto;
		margin-top: 20px;
	}

	.mobile_roadmap_scrollview {
		height: 80vh;
		width: 95vw;
		margin: 0 auto;
		overflow-y: scroll;
	}

	.mobile_roadmap_header {
		position: relative;
		margin-bottom: 30px;
	}

	.spacer {
		height: 150px;
	}
</style>
