<script>
	import AscensionMain from '../components/sections/AscensionMain.svelte'
	import {state, data} from '../stores/contracts'
</script>

<svelte:head>
	<!-- Hotjar Tracking Code for https://onironin.io/ --> 
	<script> 
		(function(h,o,t,j,a,r){
			h.hj=h.hj||function(){
				(h.hj.q=h.hj.q||[]).push(arguments)
				};
				h._hjSettings={hjid:2922448,hjsv:6};
				a=o.getElementsByTagName('head')[0];
					r=o.createElement('script');
					r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);
					})
				(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
	</script>
</svelte:head>


<div class="general_layout">
	<AscensionMain/>
</div>