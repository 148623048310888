<script>
	import NFTCollection from '../components/sections/NFTCollection.svelte';
	import NFTProfile from '../components/sections/NFTProfile.svelte';
	import HazeLayer from '../components/shared/HazeLayer.svelte';
	import BattlegroundLayer from '../components/shared/BattlegroundLayer.svelte';

	import KitsuneDetail from '../components/sections/KitsuneDetail.svelte';


	export let highlighted;
	export let returned;

	let selectedType;

	const switchView = (a) => {

		// console.log("switch view")
		//
		// console.log(a);

		selectedType = a.detail;

		highlighted = true

	};

	const returnToCollection = () => {
		highlighted = false; 
		returned = true;
	}
</script>

<svelte:head>
	<!-- Hotjar Tracking Code for https://onironin.io/ --> 
	<script> 
		(function(h,o,t,j,a,r){
			h.hj=h.hj||function(){
				(h.hj.q=h.hj.q||[]).push(arguments)
				};
				h._hjSettings={hjid:2922448,hjsv:6};
				a=o.getElementsByTagName('head')[0];
					r=o.createElement('script');
					r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);
					})
				(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
	</script>
</svelte:head>

<div class="general_layout">
	<HazeLayer/>
	<BattlegroundLayer/>
	<!-- allows us to switch between profile view based on events -->
	{#if highlighted !== true}
		<NFTCollection on:viewProfile={switchView}/>
	{:else if highlighted === true }
		{#if selectedType === "ronin"}
			<NFTProfile on:hideProfile={returnToCollection}/>
		{:else}
			<KitsuneDetail on:hideProfile={returnToCollection}/>
		{/if}

	{/if }
</div>