<!-- ComicPopup.svelte -->
<script>
    import { comicMockup } from "../../public/assets/urls";
  
      let showPopup = true;
  
      function closePopup() {
          showPopup = false;
      }
  </script>
  
  {#if showPopup}
  <div class="fixed inset-x-0 top-4 bottom-4 md:top-0 md:bottom-0 flex items-center justify-center bg-black bg-opacity-50 z-[10000]">
    <div class="bg-gray-300 rounded-lg p-4 md:p-10 w-full max-w-4xl relative m-2 md:mx-0 md:my-0 z-[10001]">
        <button on:click={closePopup} class="absolute top-3 right-3 md:top-2 md:right-4 text-3xl text-red-700 focus:outline-none z-[10002]">X</button>
          <div class="flex flex-col md:flex-row">
            <img src={comicMockup} alt="Comic Image" class="w-full md:w-1/2 max-w-sm self-center mb-2 md:mb-0"/>
            <div class="flex-initial pl-5 md:pl-10 self-center text-center md:text-left">
                <h1 class="text-black main-font text-2xl lg:text-3xl font-bold mb-1">ONI RONIN VOL . 1</h1>
                  <h2 class="text-red-700 main-font text-sm lg:text-xl font-bold mb-1">NOW AVAILABLE</h2>
                  <p class="text-black section_content mt-4">Coming to store shelves near you, the Oni Ronin comic, published by Dogu and Dark Horse is a 120+ page saga exploring cowardice, salvation and redemption in Japan's Warring States era.</p>
                  <p class="text-black section_content mt-4">Combining historically accurate characters and locations with mythical kitsune and malevolent demons from Japanese folklore, Oni Ronin is story of the cowardly Sarobei, a samurai messenger who meets feudal lords, brave warriors, and heroic ghosts on his journey of self-discovery.</p>
                  <a href="https://www.amazon.com/Oni-Ronin-Kohei-Nagamine/dp/1506741916" target="_blank" class="bg-red-700 text-white rounded-3xl px-10 py-2 mt-4 focus:outline-none hover:bg-red-600 inline-block">BUY NOW</a>
              </div>
          </div>
      </div>
  </div>
  {/if}
  