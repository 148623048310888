<style>
    .search{


        z-index: 100000;



        display: flex;
        color:white;

        flex-direction: column;

        justify-content: center;
        align-items: center;

        position: relative;

        padding:10px;
        padding-top:100px;
    }

    h2{
        margin-top:20px;

        font-family: var(--main-bold);
    }

    .sh1{
        margin-top: 5px;
        font-family: 'aaux-next';
        font-weight: bold;
    }

    .sh2{
        margin-top:10px;
        font-family: 'aaux-next';

        text-align: center;

        padding-left: 15px;
        padding-right:15px;
    }

    .inputSection-outer{
        margin-bottom:100px;

        margin-top:30px;

        display: flex;
        flex-direction: row;

        /*background: red;*/
    }

    .inputSection{
        display: flex;
        flex-direction: row;


        background: black;

        outline:#d1c155 solid 2px;

        border-radius: 10px;

        padding-left: 10px;
        padding-right: 10px;

        padding-top:    0px;
        padding-bottom: 0px;


    }

    .hash{
        font-family: 'aaux-next';
        font-style: italic;

        font-size: 1.75rem;

        padding:10px;

        /*text-align: center;*/
        /*display: flex;*/
        /*flex-direction: column;*/

    }

    .isKitsune .hash{
        padding-top:22px;
    }

    .inputText{
        background: black;
        color:white;

        font-size: 1.75rem;
        /*color#d1c155;*/

        width:100px;
    }
    .inputText:active{
        outline: none;
    }
    .inputText:focus{
        outline: none;
    }


    .hasPointer{
        cursor: pointer;
    }

    .svg-path{
        fill: none;
        stroke: #d1c155;
        stroke-width:5px;


        stroke-linecap:round;
        stroke-linejoin:round;
        stroke-miterlimit:4;
        stroke-dasharray:none;
    }
    svg{
        width:60px;
        height:60px;

    }

    .hasPointer:hover path{
        /*stroke: #d1c155   */
        stroke: white;
    }

    .hidden-path{
        display: none;
    }

</style>

<script>
    import {data} from "../stores/manager";
    import {get} from "svelte/store";
    import {navigateTo} from "svelte-router-spa";

    // function test(){
    //     console.log("A")
    // }

    export let isKitsune;

    let roninsLoaded = false;
    $:{
        roninsLoaded = $data.ronins.loaded;
    }

    let validId = false;

    let searchVal = '';

    // let found

    let found_id;

    function updateSearch(){
        let _ronins = get(data).ronins.value;

        if(searchVal.length === 0){
            validId = false;
            return;
        }

        let id_int = parseInt(searchVal);
        let id_bigInt = BigInt(searchVal);

        let id_int_asc = parseInt(searchVal)+10000;
        let id_bigInt_asc = BigInt(searchVal)+10000n;

        if(_ronins.includes(id_int) || _ronins.includes(id_bigInt) ){
            validId = true;
            found_id = id_int;
        }else if(_ronins.includes(id_int_asc) || _ronins.includes(id_bigInt_asc) ){
            validId = true;
            found_id = id_int_asc;
        }else{
            validId = false;
        }



        // console.log(">>",validId)
    }

    function arrowClick(){
        if(!validId) return;
        navigateTo("ronin/"+found_id)
    }

</script>

<div class="container search {isKitsune?'isKitsune':''}">


<!--    <h1 class="title-font text-5xl font-bold mb-2">-->
<!--        MINT-->
<!--    </h1>-->

    <h2 class="title-font text-xl mb-2">
        SEARCH RONIN
    </h2>
    <div class="sh1">Buying on secondary?</div>
    <div class="sh2">Enter the ID of a Ronin to view its details and verify if its Kitsune companion NFT has been claimed.</div>

    <div class="inputSection-outer">
        <svg >
            <path class="svg-path" d=""/>
        </svg>
        <div class="inputSection">
            <div class="hash">#</div>
            <input bind:value={searchVal} on:change={updateSearch} on:keyup={updateSearch} type="text" class="inputText"/>
        </div>
        <svg class="searchButton {validId?'hasPointer':''}" on:click={arrowClick}>
            <path class="svg-path {validId?'':'hidden-path'} " d="M 25 30 L 55 30 L 45 18   M 55 30 L 45 42"/>
        </svg>
    </div>


</div>
